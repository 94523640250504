
import { renderData, formatName } from "@libraryHelpers/dataComponents.js";
import { unescape as _unescape, isObject as _isObject } from "lodash";

export default {
	name: "CoreBlockMainNav",
	props: {
		site: {
			type: Object,
			default: () => {
				return {};
			},
		},
		isBuilder: {
			type: Boolean,
			default: false,
		},
		// we have mixins to simulate responsive breakpoints
		// which also have real responsive breakpoints
		// this should be handled w/ css not props
		// ----
		// the builder preview is set to mobile view
		isBuilderMobile: {
			type: Boolean,
			default: false,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			windowWidth: process.client ? window.innerWidth : null,
			hamVisible: false,
			hoverPageId: null,
			mobileNavVisible: false,
			windowScroll: null,
			showSkipLink: false,
			blockId: undefined,
			defaultLogo: {
				src: "/img/logos/nyl-logo-blue.svg",
				alt: "New York Life Insurance",
			},
		};
	},
	computed: {
		buttonSettings() {
			return {
				icon: this.navButtonIcon,
				label: this.navButton ? this.navButton.title : "",
				newWindow: this.navButtonLink
					? this.navButtonLink.startsWith("http")
					: false,
				type: "button",
				url: this.navButtonLink,
				variants: {
					"icon-placement": this.navButtonIconPlacement,
					style: "primary",
				},
			};
		},
		navButton() {
			return this?.site?.pages?.find((p) => {
				try {
					return JSON.parse(p.settings)?.isNavButton;
				} catch {
					return p.settings?.isNavButton;
				}
			});
		},
		navButtonDialog() {
			try {
				const dialog = JSON.parse(this.navButton?.link)?.dialog;

				return dialog;
			} catch {
				return this.navButton?.link?.dialog;
			}
		},
		navButtonIcon() {
			try {
				const icon = JSON.parse(this.navButton?.settings)?.icon;

				return icon;
			} catch {
				return this.navButton?.settings?.icon;
			}
		},
		navButtonIconPlacement() {
			try {
				const iconPlacement = JSON.parse(
					this.navButton?.settings
				)?.iconPlacement;

				return iconPlacement ?? "icon-left";
			} catch {
				return this.navButton?.settings?.iconPlacement ?? "icon-left";
			}
		},
		navButtonLink() {
			try {
				return (
					JSON.parse(this.navButton?.link)?.url ||
					`/${this.navButton?.handle}`
				);
			} catch {
				return (
					this.navButton?.link?.url || `/${this.navButton?.handle}`
				);
			}
		},
		pageTree() {
			try {
				return this?.site?.pages.filter(
					(p) =>
						!p.isPrivate &&
						!p.parentPageId &&
						this?.site?.homepageId != p.id &&
						!JSON.parse(p.settings)?.isNavButton
				);
			} catch (err) {
				return (
					this?.site?.pages.filter(
						(p) =>
							!p.isPrivate &&
							!p.parentPageId &&
							this?.site?.homepageId != p.id &&
							!p.settings?.isNavButton
					) || []
				);
			}
		},
		logoInternalLink() {
			return this.logoLink.startsWith("/");
		},
		logoTag() {
			if (this.logoInternalLink) {
				return "NuxtLink";
			}
			return "a";
		},
		blockClasses() {
			const classList = ["block", "block_main-nav"];
			if (this.navigation?.menuAlignment) {
				classList.push(
					`block_main-nav_align-${this.navigation.menuAlignment}`
				);
			}
			if (this.hamVisible) {
				classList.push("block_main-nav--subnav_open");
			}
			if (!this.isBuilder) {
				classList.push("block_main-nav--sticky_nav");
			} else {
				classList.push("block_main-nav--pointer_all");
			}
			return classList;
		},
		navigation() {
			return this.site?.navigation || {};
		},
		logo() {
			const logoSrc = _isObject(this.navigation.logo)
				? this.navigation.logo.src
				: this.navigation.logo;

			return renderData(logoSrc, this.site) || this.defaultLogo.src;
		},
		logoStyle() {
			return this.navigation.logoStyle;
		},
		logoAltText() {
			const logoAlt = _isObject(this.navigation.logo)
				? this.navigation.logo.alt
				: this.navigation.logoAltText;

			const defaultAlt =
				this.logo === this.defaultLogo.src && this.defaultLogo.alt;
			return logoAlt || defaultAlt || this.name;
		},
		logoLink() {
			return this.navigation.logoLink || "/";
		},
		hasDataName() {
			const dataName = renderData(this.navigation.name, this.site) || "";
			return !!dataName.trim(); // the default mainnav name has spaces between vars, so it will end up something like "  " even if there are no values
		},
		name() {
			const formattedName = formatName(
				this.navigation.name,
				this.site.user?.ddcUserData
			);
			return this.hasDataName
				? _unescape(renderData(formattedName, this.site))
				: this.site.title;
		},
		displayName() {
			return this.site.user?.ddcUserData?.displayName;
		},
		showUsername() {
			return this.navigation.showUsername;
		},
		showOverline() {
			return this.navigation.showOverline;
		},
		showTitle() {
			return this.navigation.showTitle;
		},
		isManagingPartner() {
			if (
				this.site.user?.ddcUserData?.marketerTitleTpDesc?.toLowerCase() ===
				"managing partner"
			) {
				return true;
			}

			return false;
		},
		overline() {
			if (this.isGO) {
				return this.goHeadquarters + " General Office";
			}
			return _unescape(renderData(this.navigation.overline, this.site));
		},
		jobTitle() {
			if (this.isManagingPartner) {
				return "Managing Partner";
			}
			return renderData(this.navigation.jobTitle, this.site);
		},
		isGO() {
			return this.site.user?.employeeType === "GO" ? true : false;
		},
		goHeadquarters() {
			return this.site.user?.ddcUserData?.orgUnitDesc;
		},
		hasButton() {
			return this.navigation?.button;
		},
		buttonLink() {
			return this.navigation?.buttonLink;
		},
		buttonText() {
			return this.navigation?.buttonText;
		},
		buttonTarget() {
			return this.navigation?.buttonTarget;
		},
		menuAlignmentClass() {
			return `navbar__nav--${this.navigation.menuAlignment}`;
		},
		logoClass() {
			return `brand_image brand_image--${this.logoStyle}`;
		},
		navCenterClasses() {
			return [
				"nav-center",
				"navbar__nav",
				"hide-xs",
				"hide-sm",
				`text-${this.navigation.menuAlignment}`,
			];
		},
		hasMoreThanOnePage() {
			return this.site.pages.length > 1;
		},
		isMobile() {
			if (this.isBuilderMobile) {
				return true;
			}
			// breakpoint needs to correspond with Core.Builder/assets/scss/mixins.scss
			const breakpoint = 992;
			const isMobile = this.windowWidth && this.windowWidth < breakpoint;
			// Assume isMobile to prevent rendering contact us on mobile before window can be accessed
			if (isMobile || isMobile === null) {
				return true;
			}
			return false;
		},
	},
	watch: {
		isMobile(nv) {
			if (!nv && this.hamVisible) this.hamVisible = false;
		},
		hamVisible(nv) {
			if (this.isMobile) {
				if (nv) {
					document.body.style.top = `-${window.scrollY}px`;
					document.body.style.position = "fixed";
				} else {
					const scrollY = document.body.style.top;
					document.body.style.position = "";
					document.body.style.top = "";
					window.scrollTo(
						0,
						parseInt(scrollY.slice(0, -2) || "0") * -1
					);
				}
			}
		},
	},
	mounted() {
		window.addEventListener("resize", this.onResize);
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.onResize);
	},
	methods: {
		handleToggle() {
			if (
				this.isBuilder &&
				this.isEditable &&
				!this.$store.state.interface.isLeftRailClosing
			) {
				if (!this.$store.state.interface.activeSidebarKey) {
					this.$store.commit(
						"interface/toggleActiveSidebarKey",
						"navigation"
					);
				} else {
					this.$store.commit("interface/setActiveSidebarKey");
				}

				this.$store.commit(
					"interface/setRightRailOpening",
					!this.$store.state.interface.isRightRailOpening
				);
				this.$store.commit("site/clearSelectedBlockIds"); // will clear selected blocks without influencing the active sidebar key
				setTimeout(() => {
					const targetElement = document.querySelector(
						".editor-main-navigation"
					);
					if (targetElement)
						targetElement.scrollIntoView({ behavior: "smooth" });
				}, 500);
			}
		},
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		toggleHamVisible() {
			if (this.isMobile) {
				this.hamVisible = !this.hamVisible;
			}
		},
		navClick(e, closeNav) {
			e.target.blur();
			e.target.parentNode.blur();
			if (closeNav) this.hamVisible = false;
		},
		handleSkipLinkFocus() {
			this.blockId = this.getBlockId();
		},
		handleSkipNavigation(event) {
			event.preventDefault();

			var firstChild = this.getFirstChild();
			if (firstChild) {
				firstChild.scrollIntoView({
					behavior: "smooth",
					block: "start",
					inline: "start",
				});
			}

			// Blur the cursor
			this.$refs.skipLink.blur();
		},
		getBlockId() {
			// Get the ID of the first child element
			var firstChild = this.getFirstChild();
			var firstChildId = firstChild ? "#" + firstChild.id : null;
			return firstChildId;
		},
		getFirstChild() {
			// Get the parent element by class name
			var parentElement = document.querySelector(".page__blocks");

			// Get the first child element using a CSS selector
			var firstChild = parentElement.querySelector(":first-child");
			return firstChild;
		},
	},
};
