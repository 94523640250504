import { render, staticRenderFns } from "./CoreBlockFooterBanner.vue?vue&type=template&id=59b5fae3"
import script from "./CoreBlockFooterBanner.vue?vue&type=script&lang=js"
export * from "./CoreBlockFooterBanner.vue?vue&type=script&lang=js"
import style0 from "./CoreBlockFooterBanner.vue?vue&type=style&index=0&id=59b5fae3&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreBlock: require('/usr/src/app/Core.Library/src/components/block/CoreBlock.vue').default})
