export const state = () => ({
	site: null,
});

export const getters = {
	getThemeByKey: (state) => (themeKey) => {
		if (state.site.themes) {
			return state.site.themes[themeKey];
		}
		return null;
	},
	getPageByHandle: (state) => (pageHandle) => {
		return {
			...state.site?.pages?.find((page) => {
				// if (page.parentPageId) {
				// 	let parentPage = state.site.pages.find(
				// 		(p) => page.parentPageId === p.id
				// 	);
				// 	if (parentPage?.handle) {
				// 		return (
				// 			`${parentPage?.handle}/${page?.handle}` ===
				// 			pageHandle
				// 		);
				// 	} else {
				// 		return page?.handle === pageHandle;
				// 	}
				// } else {
				return page?.handle === pageHandle;
			}),
			head: JSON.parse(state.site.head || "{}"),
		};
	},
	getHomePage: (state) => {
		return {
			...state.site.pages.find((page) => {
				return page.id === state.site.homepageId;
			}),
			head: JSON.parse(state.site.head || "{}"),
		};
	},
	pageMap: (state) => state?.site?.pages.map((p) => p.handle) || [],
	"site/pageIsFolder": (state) => (pageId) => {
		const parseString = (str) => {
			try {
				return JSON.parse(str);
			} catch {
				return null;
			}
		};

		const page = state?.site?.pages.find((p) => p.id === pageId);
		const pageSettings = parseString(page?.settings);
		const isLink = Boolean(parseString(page?.link));
		const pageBlocks = parseString(page?.blocks);
		const pageAsFolder = pageSettings?.pageAsFolder ?? false;
		const hasBlocks = pageBlocks?.length > 0;

		if (pageAsFolder) return false;
		return !hasBlocks && !isLink;
	},
};

export const actions = {
	async nuxtServerInit({ commit }, ctx) {
		commit("setSite", ctx.$config.siteData);

		const response = await this.$axios.$get(`/settings/featureflags`);
		commit("settings/setFeatureFlags", response);
	},
};

export const mutations = {
	setSite(state, siteData) {
		state.site = siteData;
		state.site.copyrightYear = new Date().getFullYear();
	},
};
